.title{
    flex-wrap: wrap;
}

[class*="word-"]{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    pointer-events: none;

    &:not(:first-child){
        margin-left: .5ch;
    }
}

[class*="char-"]{
    min-width: .5ch;
    pointer-events: none;
}
