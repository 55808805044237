// header {
//     position: fixed;
//     top: 0;
//     right: 0;
//     left: 0;
//     z-index: 10;
//     pointer-events: none;

//     transition: opacity 1s ease;

//     @include media-breakpoint-up(md){
//         padding: 2rem;
//     }

//     .developed{
//         opacity: .25;
//         transition: opacity 1.4s ease;

//         &:hover{
//             opacity: 1;
//         }
//     }

//     &.lg-active{
//         #menuToggler,
//         #claim,
//         #darkmode,
//         #select-language,
//         #pageTitle{
//             opacity: 0;
//             pointer-events: none;
//         }

//         #company{
//             opacity: .15 !important;
//         }
//     }
// }



header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background-color: transparent;
    pointer-events: none;
    z-index: 10;
    // will-change: transform;
    // transition: transform .25s ease;
    
    @include media-breakpoint-up(md){
        padding: 2rem;
    }


    .navbar{
        will-change: padding-top, padding-bottom;
        transition: padding 1s ease;
        z-index: 1;

        @include media-breakpoint-down(sm){
            padding: {
                top: 0 !important;
                bottom: 0 !important;
            }
        }
    }

    // Brand
    .navbar-brand{
        z-index: 1;
        pointer-events: all;

        #headerLogo{
            display: block;
            height: 60px;
            // width: 200px;
            clip-path: polygon(29.5% 0, 100% 0, 100% 100%, 29.5% 100%);
            transform: translateX(-29.5%);
            transition: clip-path .7s ease, transform .7s ease;

            @include media-breakpoint-down(sm){
                height: 50px;
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                transform: translateX(0);
            }
        }

        a{
            &:hover{
                #headerLogo{
                    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                    transform: translateX(0);
                }
            }
        }
    }

    .claim{
        display: block;
        position: absolute;
        top: calc(100% + 3rem);
        left: 1rem;
        width: 120px;
        font-size: .8em;
        text-transform: uppercase;
        letter-spacing: .1em;
        z-index: 1;
        will-change: opacity;
        transition: opacity 1.2s ease;
    }

    // MENU TOGGLER
    #menu-toggler {
        display: block;
        padding: .5em;
        height: 40px;
        width: 40px;
        background-color: transparent;
        border: none;
        z-index: 10;
        pointer-events: all;
        cursor: pointer;
        will-change: opacity, transfrom;
        transition: opacity .7s ease, transform .5s ease;

        .menu-toggler-bar {
            display: block;
            margin-top: .35rem;
            height: 1px;
            width: 100%;
            background-color: $white;
            will-change: margin, width, background-color, transform;
            transition: margin .2s ease, width .2s ease, transform .2s ease;

            &:nth-child(1) {
                margin-top: 0;
                margin-right: auto;
                width: 100%;
            }

            &:nth-child(2) {
                margin-right: 0;
                margin-left: auto;
                width: 100%;
            }
        }

        &:hover{
            .menu-toggler-bar {
    
                &:nth-child(1) {
                    width: 80%;
                }
    
                &:nth-child(2) {
                    width: 60%;
                }
            }
        }

        &.active {
            transform: translateY(10%);

            .menu-toggler-bar {
                &:nth-child(1) {
                    width: 100%;
                    transform: translate(0, 50%) rotate(-45deg);
                }

                &:nth-child(2) {
                    margin-top: 0;
                    width: 100%;
                    transform: translate(0, -50%) rotate(45deg);
                }

                &:nth-child(3) {
                    width: 0;
                    transform: translate(0, .5em);
                }
            }
        }
    }

    //ACTIONS
    .action{
        //
    }

    #main-menu{
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100vh;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: mix($white, $black, 10);
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
        transition: clip-path .7s ease;
        pointer-events: none;
        z-index: 0;

        ul{
            display: block;

            .nav-item{
                @extend .h2;

                @include media-breakpoint-down(sm){
                    font-size: 7vmin;
                };

                .nav-link{
                    line-height: 1;

                    @include media-breakpoint-down(sm){
                        padding-left: 0;
                    }
                }
            }
        }

        &.active{
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            pointer-events: all;
        }
    }

    // Company infos
    #company{
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        line-height: 1;
        opacity: 0;
        transform: translateY(0);
        will-change: opacity, transform;
        transition: opacity 1.2s ease, transform 1.2s ease;
        pointer-events: none;

        .company-info{
            transition: transform 1.4s ease, opacity 1s ease;
        }

        #socials{
            li{
                margin-right: .5em;
                font-size: 1.75rem;
            }
        }

        .privacy{
            font-family: $system-font;
            font-size: 80%;
            font-weight: 300;
            opacity: 0;
            transform: translateY(10%);
            transition: opacity 1.2s ease, transform 1.2s ease;
            pointer-events: none;
        }

        &.visible{
            opacity: 1;
            transform: translateY(0);
            pointer-events: all;
        }

        &.active{
            .privacy{
                opacity: 1;
                transform: translateY(0);
                pointer-events: all;
            }

            @include media-breakpoint-down(sm){
                .company-info{
                    opacity: .25;
                    transform: translateY(-.5em);
                }
            }
        }
    }

    .developed{
        opacity: .25;
        transition: opacity 1.4s ease;

        &:hover{
            opacity: 1;
        }
    }
}
