section.slideshow{
    height: 250px;

    @include media-breakpoint-up(md){
        height: 100vh;
    }

    [class*="slick-"]{
        height: 100%;

        & > div{
            height: 100%;
        }
    }

    img{
        @include object(cover, center)
    }
}