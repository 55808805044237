#intro{
    .title{
        font-size: 3.85rem;

        @include media-breakpoint-down(sm){
            font-size: 2.5rem;
        }

        &::before{
            content: '';
            display: block;
            margin-bottom: 2rem;
            height: 1px;
            width: 3rem;
            background-color: $white;
        }
    }
}
