#select-language{
    height: 40px;
    transition: opacity 1.2s ease;

    .nav,
    select{
        -webkit-appearance: none;
        padding: .5rem .5rem .5rem 0;
        min-width: unset;
        color: $white;
        font-size: 1rem;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: .05ch;
        background-color: transparent;
        background-image: url('../img/vectors/icon-carret.svg');
        background-repeat: no-repeat;
        background-position: right 50%;
        background-size: .5em;
        border: none;
        outline: none;
        pointer-events: all;

        @include media-breakpoint-up(md){
            padding: 1rem 1rem 1rem 0;
        }
    }

    .nav{
        padding: 1rem 0;

        button{
            color: inherit;
            font-weight: inherit;
            text-transform: inherit;
            background-color: inherit;
            border: inherit;
        }

        .dropdown-menu{
            padding: 0;
            min-width: unset;
            background-color: $gray-900;

            .dropdown-item{
                color: $white;
                font-weight: bold;

                &:hover{
                    color: $white;
                    background-color: mix($white,$gray-900,5);
                }
                &:focus,
                &:active{
                    background-color: $gray-900;
                }
            }
        }
    }
}