body.default{
    header{
        position: sticky;
    }
}
.page-default {

    #content{
        pointer-events: none;
        overflow: hidden;

        @include media-breakpoint-up(sm){
            min-height: 90vh;
        }

        .content{
            position: relative;
            overflow: hidden;
            z-index: 1;
            pointer-events: all;
        }

        .cover{
            position: absolute;
            top: 50%;
            right: 0;
            height: 90%;
            width: 50vw;
            object-fit: contain;
            filter: saturate(0);
            border-radius: .3em;
            opacity: .15;
            transform: translate(10%,-50%);
            transition: filter 1.5s ease, opacity 1.5s ease, transform 1.5s ease;
            pointer-events: all;
            z-index: 0;

            // &:hover{
            //     opacity: .25;
            //     transform: translate(0,-50%);
            //     filter: saturate(1);
            // }
        }
    }
}
