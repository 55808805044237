.page-products{
    header{
        position: fixed;
    }

    #catalogue{
        min-height: 100vh;
    }
    .product-line{
        position: relative;
        height: 400px;
        width: 300px;

        @include media-breakpoint-down(sm){
            height: 420px;
            width: 100%;
            margin-bottom: 1rem;
        }

        a{
            color: inherit;
            text-decoration: none;

            &:hover{
                .title{
                    opacity: 0;
                    transform: translateY(1rem);
                }
                img{
                    opacity: 1;
                }
                button{
                    opacity: 1;
                    &:after{
                        width: 20px;
                    }
                }
            }
        }

        .title{
            transition: opacity .7s ease, transform .7s ease;
        }

        .info{
            position: absolute;
            width: 100%;
            left: 50%;
            bottom: 0;
            transform: translate(-50%);
        }

        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
            opacity: .2;
            transition: opacity 1.2s ease;
        }
    }
}
