.safari{
    h1,
    .h1 {
        font-weight: 700;
    }

    h2, .h2,
    h3, .h3 {
        font-weight: 600;
    }

    // header{
    //     position: sticky;
    // }

    #main-menu{
        position: absolute;
    }

    // #app{
    //     position: relative;

    // }

    .lg-outer{
        // height: 100vh;
        position: sticky;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &.ios{
        .lg-toolbar{
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
        }

        .lg-actions{
            position: fixed;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }
}