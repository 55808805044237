#videopopup{
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba($black,.95);
    z-index: 11;
    opacity: 0;
    pointer-events: none;
    transition: opacity .4s ease;

    .btn-close{
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        color: $white;
        float: right;
        font-size: 24px;
        line-height: 0;
        padding: 10px 0;
        text-align: center;
        text-decoration: none;
        outline: 0;
        background-color: transparent;
        border: none;
        transform: translateY(-100%);
        transition: color .2s linear, transform .4s ease;
        transition-delay: transform .25s;
        cursor: pointer;
        z-index: 10;

        @include media-breakpoint-down(sm){
            bottom: unset;
            left: unset;
            height: 50px;
            width: 50px;
            background-color: $gray-900;
        };

        .icon-close{
            @include media-breakpoint-down(sm){
                &:before{
                    content: '+';
                    display: inline-block;
                    font-size: 1.5rem;
                    font-style: normal;
                    transform: rotate(-45deg);
                }
            };
        }
    }

    video{
        position: fixed;
        top: 50%;
        left: 50%;
        height: 100%;
        width: 100%;
        object-fit: contain;
        object-position: center;
        transform: translate(-50%,-50%);
    }

    &.active{
        opacity: 1;
        pointer-events:all;

        .btn-close{
            transform: translateY(0);
        }
    }
}

.video-player{
    display: flex;
    position: relative;
    max-width: 100%;
    justify-content: center;
    align-items: center;
    background-color: $black;
    overflow: hidden;
    z-index: 12;

    video{
        max-width: 100%;
        z-index: 1;
        pointer-events: none;
    }

    .video-topbar{
        display: flex;
        position: absolute;
        margin: auto;
        bottom: 9rem;
        color: $white;
        font-weight: bold;
        line-height: 1;
        justify-content: center;
        align-items: center;
        z-index: 2;
        transition: all .5s ease;

        .caption{
            margin-left: .5em;
            font-weight: normal;
        }
    }

    .video-controls{
        position: fixed;
        bottom: 1rem;
        padding: 1rem;
        width: 98vw;
        max-width: 100%;
        color: $white;
        justify-content: center;
        align-items: center;
        // background-color: red;
        overflow: hidden;
        z-index: 12;
        transition: transform .5s ease, opacity .7s ease;

        @include media-breakpoint-down(sm){
            top: auto;
            bottom: 5px;
            right: 5px;
            left: 5px;
        }

        .seekbar{
            position: relative;
            width: 100%;
            height: 4px;
            background-color: rgba($white,.2);
            overflow: hidden;
            transition: height .4s ease;
            cursor: pointer;

            .progress{
                position: absolute;
                margin: auto;
                top: 0;
                left: 0;
                bottom: 0;
                width: 0;
                background-color: rgba($white,.8);
                transition: all .25s linear;
            }

            &:hover{
                height: 10px;
            }
        }

        // Buttons
        .control-btn{
            display: flex;
            position: relative;
            padding: 0;
            height: 45px;
            min-height: 45px;
            width: 45px;
            min-width: 45px;
            color: $white;
            justify-content: center;
            align-items: center;
            background-color: rgba($white, 0);
            border: 0;
            outline: 0;
            transition: background-color .4s ease;
            cursor: pointer;

            i{
                position: absolute;
            }

            &:hover{
                background-color: rgba($white, .25);
            }
        }

        // PlayBack
        .video-playback-controls{
            display: flex;
            height: 65px;
            align-items: center;

            .video-title{
                margin: 0 1.2rem;
                font-size: .8rem;
                font-weight: bold;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                transform: translateY(.5rem);
                opacity: 0;
                transition: opacity .4s ease, transform .4s ease;

                &:hover{
                        opacity: 1;
                        transform: translateY(0);
                }

                .caption{
                    margin-left: .5em;
                    font-weight: normal;
                }
            }

            .toggle-play-pause{
                &.play{
                    .pause-icon{
                        display: none;
                    }
                }
                &.pause{
                    .play-icon{
                        display: none;
                    }
                }

                &:hover{
                    // background-color: rgba($white, .35);
                }
                &:active{
                    background-color: $gray-900;
                }
            }

            .toggle-volume{
                margin-right: .5rem;
                background-color: rgba($white, 0);

                &:after{
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    height: 3px;
                    width: 40%;
                    background-color: rgba($white, .3);
                    border-radius: 4px;
                    opacity: 0;
                    transform: translateY(100%);
                    transition: transform .7s ease, opacity .7s ease;
                }

                &.on{
                    .volume-off{
                        display: none;
                    }
                }
                &.off{
                    .volume-on{
                        display: none;
                    }

                    &:after{
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }

            .video-volume-control{
                display: flex;
                margin-right: .5rem;
                align-items: center;
                height: 2px;
                width: 120px;

                @include media-breakpoint-down(sm){
                    width: 400px;
                }

                .volume-seekbar{
                    width: 100%;
                    height: 4px;
                    transition: width .7s ease;

                    &.disabled{
                        width: 0;
                    }
                }
            }

            .video-timing{
                display: flex;
                margin-right: .5rem;
                margin-left: auto;
                align-items: center;
                justify-content: center;
                font-size: .8rem;

                .time{
                    width: 50px;
                    text-align: center;

                    &.start-time{
                        display: none;
                    }

                    &.end-time{
                        font-family: sans-serif;
                        padding: 0 .5em;
                        color: rgba($white, .7);
                        background-color: rgba($black, .05);
                        border-radius: 1em;
                    }
                }
            }
        }
    }

    &.play{

    }

    &.pause{
        .video-controls{
            // opacity: 0;
            // transform: translateY(-100%);

            @include media-breakpoint-down(sm){
                // transform: translateY(100%);
            }
        }
    }

    &.hide-controls{
        .video-controls{
            opacity: 0;
            // transform: translateY(-100%);

            @include media-breakpoint-down(sm){
                // transform: translateY(100%);
            }
        }
    }
}
