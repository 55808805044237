section.focus-links{
    .page-link{
        position: relative;
        height: 350px;
        // width: 300px;
        background-color: transparent;
        border: none;

        @include media-breakpoint-down(sm){
            height: 420px;
            width: 100%;
            margin-bottom: 1rem;
        }

        a{
            color: $white;;
            text-decoration: none;

            &:hover{
                .title{
                    color: $white;
                    opacity: 0;
                    transform: translateY(1rem);
                }
                img{
                    opacity: 1;
                }
                button{
                    opacity: 1;
                    &:after{
                        width: 20px;
                    }
                }
            }
        }

        .title{
            transition: opacity .7s ease, transform .7s ease;
        }

        .info{
            position: absolute;
            width: 100%;
            left: 50%;
            bottom: 0;
            transform: translate(-50%);
        }

        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
            opacity: .2;
            transition: opacity 1.2s ease;
        }
    }
}