*{
    &::selection{
        color: mix($white,complement($blue),40%) !important;
    }
}

html {
  scroll-behavior: smooth;
}

body{
    // position: relative;
    // overflow: hidden;
    min-height: 100vh;
    // min-height: -wekit-fill-available;
    color: $white;
    background-color: mix($white, $black, 7.5);
    overflow-y: auto;
    overflow-x: hidden;
}

main,
#app{
    // position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    @include media-breakpoint-down(sm){
        // overflow-x: hidden;
    }
}

.app-page{
    // position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    // width: 100%;
    // height: 100%;
    background-color: mix($white, $black, 7.5);
    // overflow: hidden;
    // pointer-events: none;

    .app-scroll{
        overflow: hidden;
        will-change: transform;
        z-index: 2;
    }
}

.app-overlay{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-family: $brand-font;
    font-size: 7vmax;
    font-weight: bold;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    background-color: mix($white, $black, 9);
    will-change: transform, clip-path;
    transition: clip-path .1s ease;
    z-index: 11;

    @include media-breakpoint-down(sm){
        font-size: 13vmin;
        letter-spacing: .03em;
        -webkit-text-stroke: 1px;
        z-index: 1200;
    }

    &.clip{
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
        transition: clip-path .7s ease;
    }
}

section{
    position: relative;
    z-index: 1;
}

// Optional 'Visually Hide GRecaptcha'
.grecaptcha-badge{
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
}
