#parallax-nav{
    .nav{
        will-change: transform;

        @include media-breakpoint-down(sm){
            flex-wrap: nowrap;
        }

        li{
            a{
                display: inline-flex;
                font-family: $brand-font;
                font-size: 6rem;
                font-weight: bold;
                line-height: 1;
                letter-spacing: .05ch;
                white-space: nowrap;
                opacity: .1;
                transition: 1.2s ease;

                @include media-breakpoint-down(sm){
                    padding: .25rem .5rem;
                    font-size: 2.5rem;
                }

                [class="char-"]{
                    will-change: opacity;
                }

                &:hover{
                    opacity: 1;
                }
            }
        }
    }
}
