#hero{
    min-height: 100vh;
    overflow: visible;
    margin-bottom: 100px;

    @include media-breakpoint-down(sm){
        min-height: calc(100vh - 3rem);
    }

    .title{
        @include media-breakpoint-up(sm){
            font-size: 3rem;
        }
    }

    .app-slider{
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        // clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);

        .nav-container{
            button{
                height: 150%;
                max-height: 100%;
                min-height: 200px;

                &:before{
                    content: '';
                    display: block;
                    height: 100%;
                    width: 100%;
                    background-color: $white;
                    transition: background-color .25s ease;
                }

                &.slick-prev{
                    left: 0;
                    padding-right: 18px;
                    transition: padding-right .25s ease, max-height .25s ease;
                }

                &.slick-next{
                    right: 0;
                    padding-left: 18px;
                    transition: padding-left .25s ease, max-height .25s ease;
                }

                &:hover{
                    max-height: 150%;

                    &:before{
                        background-color: $blue;
                    }

                    &.slick-prev{
                        padding-right: 16px;
                    }

                    &.slick-next{
                        padding-left: 16px;
                    }
                }
            }
        }

        .carousel{
            margin-top: auto;
            margin-right: auto;
            margin-bottom: auto;
            height: 500px;
            width: 700px;
            opacity: .2;
            filter: saturate(0);

            @include media-breakpoint-down(md){
                position: absolute;
                top: 50%;
                height: 300px;
                width: 60vw;
                transform: translateY(-70%);
            }

            .slick-list,
            .slick-track{
                height: 100%;
                overflow: visible;
                // clip-path: polygon(0 -100px, 100% -100px, 100% 100%, 0 100%);

                div,
                a{
                    display: block;
                    height: 100%;
                    outline: none;
                }
            }

            video,
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
                border-radius: .25em;
            }
        }

        .thumbnail{
            margin-top: auto;
            margin-bottom: -100px;
            margin-left: auto;
            height: 450px;
            width: 700px;
            transform: translateX(-20%);

            @include media-breakpoint-down(md){
                // position: absolute;
                margin-bottom: 2rem;
                height: 200px;
                width: 80vw;
            }

            .slick-list,
            .slick-track{
                height: 100%;
                overflow: visible;
                // clip-path: polygon(0 -100px, 100% -100px, 100% 100%, 0 100%);

                div,
                a{
                    display: block;
                    height: 100%;
                    outline: none;
                }
            }

            .slick-slide{
                opacity: 0;
                outline: none;
                transition: transform 1.2s ease, opacity 1.2s ease;
            }
            .slick-current, .slick-current~.slick-slide {
                opacity: 1;
            }

            .slick-current{
                transition: transform 1.2s ease, opacity 1.2s ease;

                &:hover,
                &.hovering{
                    transform: translateY(-100px);

                    @include media-breakpoint-down(sm){
                        transform: translateY(-5%);
                        border: 2px solid;
                    }
                }
            }

            video,
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
                border-radius: .25em;
            }
        }
    }
}
