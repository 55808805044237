.page-casestudy-article{
    header{
        position: fixed;
    }

    .container{
        z-index: 2;
    }

    #content{
        pointer-events: none;
        overflow: hidden;

        @include media-breakpoint-up(sm){
            min-height: 100vh;
        }

        .content{
            position: relative;
            overflow: hidden;
            z-index: 1;
            pointer-events: all;
        }

        .cover{
            position: absolute;
            top: 50%;
            right: 0;
            height: 500px;
            width: 50vw;
            object-fit: cover;
            filter: saturate(0);
            border-radius: .3em;
            opacity: .35;
            transform: translate(10%,-50%);
            transition: filter 1.5s ease, opacity 1.5s ease, transform 1.5s ease;
            pointer-events: all;
            z-index: 0;

            // &:hover{
            //     opacity: .25;
            //     transform: translate(0,-50%);
            //     filter: saturate(1);
            // }
        }
    }

    #cover{
        position: relative;
        height: 100vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        filter: saturate(1);

        &:before{
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            // background-color: $black;
            background-image: linear-gradient(rgba($black, .15),$black);
            // mix-blend-mode: color;
            z-index: 2;
        }

        .cover{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            opacity: 1;
            z-index: 1;
        }

        .case-title{
            position: relative;
            text-decoration: none;
            pointer-events: all;
            z-index: 1;

            &:hover{
                text-decoration: none;
            }

            .title{
                font-size: 7vmax;
                letter-spacing: .05em;
                -webkit-text-stroke: 2px;
                pointer-events: none;

                @include media-breakpoint-down(sm){
                    font-size: 13vmin;
                    letter-spacing: .03em;
                    -webkit-text-stroke: 1px;
                }
            }
        }
    }

    #next-project{
        @include media-breakpoint-down(sm){
            overflow: hidden;
        }

        a{
            @include media-breakpoint-up(sm){
                .title{
                    opacity: .1;
                    will-change: opacity, transform;
                    transition: opacity .7s ease;

                    span{
                        pointer-events: none;
                    }
                }

                &:hover{
                    .title{
                        opacity: 1;
                    }
                }
            }
        }
    }
}
