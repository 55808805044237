.page-portfolio-article{
    header{
        position: fixed;
    }

    .container{
        z-index: 2;
    }

    #cover{
        position: relative;
        height: 100vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        filter: saturate(0);

        &:before{
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: $black;
            mix-blend-mode: color;
            z-index: 1;
        }

        .cover{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            opacity: .45;
            z-index: 1;
        }

        .case-title{
            position: relative;
            text-decoration: none;
            pointer-events: all;
            z-index: 1;

            &:hover{
                text-decoration: none;
            }

            .title{
                font-size: 7vmax;
                letter-spacing: .05em;
                -webkit-text-stroke: 2px;
                pointer-events: none;

                @include media-breakpoint-down(sm){
                    font-size: 13vmin;
                    letter-spacing: .03em;
                    -webkit-text-stroke: 1px;
                }
            }
        }
    }

    #gallery{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 50vh 50vh;
        z-index: 2;

        @include media-breakpoint-down(sm){
            grid-auto-rows: 25vh 25vh;
        }


        img,
        video{
            height: 100%;
            width: 100%;
            object-fit: cover;

            &:nth-child(1){
                grid-column: 1/3;
                grid-row: 1/3;
            }

            &:nth-child(2){
                grid-column: 1/2;
                grid-row: 3/5;
            }

            &:nth-child(5){
                grid-column: 1/3;
                grid-row: 5/7;
            }

            &:nth-child(6),
            &:nth-child(7){
                grid-row: 7/9;
            }

            &:nth-child(8){
                grid-column: 2/3;
                grid-row: 9/11;
            }

            &:nth-child(11){
                grid-column: 1/3;
                grid-row: 11/13;
            }

            // &:last-child{
            //     grid-column: 1/3;
            // }
        }
    }

    #next-project{
        @include media-breakpoint-down(sm){
            overflow: hidden;
        }

        a{
            @include media-breakpoint-up(sm){
                .title{
                    opacity: .1;
                    will-change: opacity, transform;
                    transition: opacity .7s ease;

                    span{
                        pointer-events: none;
                    }
                }

                &:hover{
                    .title{
                        opacity: 1;
                    }
                }
            }
        }
    }
}
