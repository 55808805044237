.page-casestudies{
    .app-scroll{
        @include media-breakpoint-up(md){
            position: relative;
            z-index: 1
        };
    }

    .page-title{
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 14rem;
        text-align: center;
        white-space: nowrap;
        opacity: .025;
        z-index: 0;
    }

    #gallery{
        position: relative;
        z-index: 1;

        @include media-breakpoint-up(sm){
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-auto-rows: 33.3vh 33.3vh 33.3vh;
            grid-gap: 1.5rem;
        }

        &.hovering{
            article{
                img{
                    opacity: .05;
                }

                &.active{
                    filter: saturate(1);

                    .title{
                        opacity: 0;
                        transform: translateY(10%);

                        &:after{
                            width: 0;
                        }
                    }
                    img{
                        opacity: 1;
                    }
                }
            }
        }

        article{
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: .3em;
            overflow: hidden;
            filter: saturate(0);

            @include media-breakpoint-down(sm){
                margin-bottom: 1rem;
            };

            &:nth-child(1){
                grid-column: 1/3;
                grid-row: 1/3;

                .title{
                    &:after{
                        display: block;
                    }
                }
            }

            &:nth-child(2){
                grid-column: 3/4;
                grid-row: 1/2;
            }

            &:nth-child(3){
                grid-column: 3/4;
                grid-row: 2/4;
            }

            &:nth-child(4){
                grid-column: 1/2;
                grid-row: 3/4;
            }

            &:nth-child(5){
                grid-column: 2/3;
                grid-row: 3/4;
            }

            &:nth-child(6){
                grid-column: 1/2;
                grid-row: 4/6;

                .title{
                    &:after{
                        display: block;
                    }
                }
            }

            &:nth-child(7){
                grid-column: 2/4;
                grid-row: 4/6;

                .title{
                    &:after{
                        display: block;
                    }
                }
            }

            // &:last-child{
            //     grid-column: 1/4;
            // }

            a{
                display: flex;
                align-items: flex-end;
                position: relative;
                height: 100%;
            }

            .title{
                position: absolute;
                left: 2rem;
                bottom: 2rem;
                z-index: 1;
                transition: opacity .4s ease, transform .4s ease;
                pointer-events: none;

                @include media-breakpoint-up(sm){
                    &:after{
                        content: '';
                        display: none;
                        position: absolute;
                        top: 50%;
                        left: 105%;
                        height: 1px;
                        width: 30%;
                        background-color: $white;
                        transition: width .4s ease;
                    }
                };
            }

            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
                opacity: 1;
                opacity: .25;
                transition: opacity .4s ease;
                z-index: 0;
                pointer-events: none;
            }
        }
    }
}
