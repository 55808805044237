#cover{
    overflow: hidden;
    
    [class*="word-"]{
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);

        &:not(:first-child){
            margin-left: .5ch;
        }

        [class*="char-"]{
            display: inline-block;
        }
    }
}
