section.plant-legend{
    color: $black;
    background-color: $white;

    .planimetry{
        // filter: invert(1);
        display: block;
        max-height: 100vh;
        margin-left: auto;
    }
    
    .lamps{
        .lamp{
            display: inline-block;
            height: 80px;
            width: 80px;
            margin: 1em 0;
            // opacity: .25;
            will-change: opacity;
            transition: opacity .4s ease;

            &:hover{
                opacity: 1;

                img{
                    opacity: 1;
                }
            }

            img{
                height: 80px;
                width: 80px;
                object-fit: cover;
                object-position: center;
                opacity: .25;
                will-change: opacity;
                transition: opacity .4s ease;
            }

            .color{
                // position: absolute;
                display: inline-block;
                top: .5em;
                right: .5em;
                height: .7em;
                width: .7em;
                border-radius: 50%;
                // filter: invert(1);
            }

            .datas{
                .producer{
                    font-size: .8rem;

                    .color{
                        display: inline-block;
                        height: .7em;
                        width: .7em;
                        border-radius: 50%;
                        // filter: invert(1);
                    }

                    label{
                        opacity: .25;
                    }
                }

                .abstract{
                    font-size: .9rem;
                }
            }
        }
    }
}