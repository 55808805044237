/* B-Ahead Official Font - Graphik - These is the stylesheet to include Graphik Font to web puclications */

@font-face {
	font-family: 'Basetica';
	src: url('../fonts/Basetica-Med.eot');
	src: url('../fonts/Basetica-Med.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Basetica-Med.woff2') format('woff2'),
	url('../fonts/Basetica-Med.woff') format('woff'),
	url('../fonts/Basetica-Med.otf') format('opentype'),
	url('../fonts/Basetica-Med.ttf') format('truetype'),
	url('../fonts/Basetica-Med.svg#') format('svg');
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
}

/*
// ICON SET
*/

i {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'Ionicons' !important;
	speak: none;
	font-size: 1rem;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	vertical-align: middle;
	line-height: initial;

	display: inline-block;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// @import '../abstracts/_charset.scss';

[class*='icon-']{
	display: inline-block;

	&:before{
		content: '';
		display: block;
		height: 1em;
		width: 1em;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
	}
}

.icon-play {
	&:before {
		background-image: url('../img/vectors/icon-play.svg');
	}
}

.icon-stop {
	&:before {
		background-image: url('../img/vectors/icon-stop.svg');
	}
}

.icon-soundoff {
	&:before {
		background-image: url('../img/vectors/icon-soundoff.svg');
	}
}

.icon-soundon {
	&:before {
		background-image: url('../img/vectors/icon-soundon.svg');
	}
}

.icon-facebook {
	&:before {
		background-image: url('../img/vectors/icon-facebook.svg');
	}
}

.icon-instagram {
	&:before {
		background-image: url('../img/vectors/icon-instagram.svg');
	}
}

.icon-linkedin {
	&:before {
		background-image: url('../img/vectors/icon-linkedin.svg');
	}
}

.icon-dev {
	font-size: 1.5rem;

	&:before {
		background-image: url('../img/vectors/icon-dev.svg');
	}
}
