// Darkmode
#darkmode{
    display: block;
    padding: .35em;
    height: 40px;
    width: 40px;
    background-color: transparent;
    border: none;
    z-index: 1;
    pointer-events: all;
    will-change: opacity;
    transition: opacity 1.2s ease;

    @include media-breakpoint-down(sm){
        padding: .5em;
    }

    svg{
        height: 100%;
        width: 100%;

        line {
            stroke: $white;
            stroke-dasharray: 100;
            stroke-dashoffset: 0;
            transition: stroke-dashoffset .25s linear;
        }

        .main-circle{
            fill: transparent;
            stroke: $white;
            stroke-width: 30;
            transition: fill .3s ease, stroke-width .3s ease;
        }

        .moon-mask{
            // fill: $white;
            transform: translate(50%,-50%);
            transition: transform .3s ease;
        }
    }

    &:hover{
        svg{

            line {
                stroke-dashoffset: 100;
            }

            .main-circle{
                fill: $white;
                stroke-width: 20;
            }

            .moon-mask{
                transform: translate(0, 0);
            }
        }
    }

    &.active{
        svg{

            line {
                stroke-dashoffset: 100;
            }

            .main-circle{
                fill: $white;
                stroke-width: 20;
            }

            .moon-mask{
                fill: $black;
                transform: translate(0, 0);
            }
        }

        &:hover{
            svg{
                line {
                    stroke-dashoffset: 0;
                }

                .main-circle{
                    // fill: $black;
                    // stroke: $white;
                    stroke-width: 30;
                }

                .moon-mask{
                    transform: translate(50%,-50%);
                }
            }
        }
    }
}