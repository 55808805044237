form{
    label{
        position: relative;
        width: 100%;

        span{
            &:first-of-type{
                display: inline-block;
                padding: .25em .5em;
                color: $white;
                font-family: $brand-font;
                font-size: .9em;
                font-weight: 700;
                // text-transform: uppercase;
                transition: bottom .4s ease, padding .4s ease;
                pointer-events: none;
            }

            &.gdpr,
            &.marketing,
            &.terms{
                padding: 0;
                color: $gray-400;

                span{
                    font-size: 1rem;
                    padding: 0;
                }

            }
        }
    }

    small{
        color: $gray-500;

        a{
            color: $gray-600
        }
    }

    input,
    textarea{
        width: 100%;
        padding: .8em 1em;
        color: $white;
        background-color: transparent;
        border: 1px solid $gray-300;
        // border-radius: .3em;
        outline: none;


        &[type="checkbox"]{
            width: auto;
            padding: inherit;
        }

        &[type="submit"]{
            width: auto;

            &:disabled{
                opacity: .25;
            }
        }

        &:focus{
            border: 1px solid $yellow;
            box-shadow: 0 0 0 .25em rgba($yellow, .1);
        }

        &:focus ~ span{
            padding: 0 .5em;
            bottom: 90%;
        }

        &::placeholder{
            color: $gray-800;
        }
    }

    textarea{
        height: 120px;
    }

    select{
        display: block;
        padding: .8em 1em;
        width: 100%;
        padding: .5em;
        color: $black;
        background-color: transparent;
        border: 1px solid $gray-300;
        border-radius: .3em;
        outline: none;
        -webkit-appearance: none;

        &:focus{
            border: 1px solid $yellow;
            box-shadow: 0 0 0 .25em rgba($yellow, .25);
        }
    }

    .gdpr{
        padding: 0;
        width: auto;
    }
    input[type="submit"],
    button[type="submit"]{
        padding: .8em 1em;
        width: 100%;
        min-width: 200px;
        color: $black;
        font-family: $brand-font;
        font-size: .9em;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: .05em;
        background-color: $white;
        border-radius: 0;
    }

    @import '_cf7forms-overrite.scss';
}
