.page-product{
    .subtitle{
        font-family: $system-font;
        font-weight: bold;
        letter-spacing: .1em;
        text-transform: uppercase;
    }

    #preview{
        min-height: 100vh;
    }
    
    #preview,
    #additional-info{
        // font-family: $system-font;
        // font-weight: 300;
        line-height: 1.3;

        .technical-sheet{
            font-size: .9rem;


            .certifications{
                img{
                    height: 1.5rem;
                }

                .ip-class{
                    display: inline-block;
                    padding: .1em .5em;
                    font-family: $system-font;
                    font-weight: normal;
                    border: 1px solid $white;
                }

                .energy{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 2rem;
                    width: 3.8rem;
                    font-size: 1.3rem;
                    font-weight: bold;
                    text-align: center;
                    background-color: $green;
                    clip-path: polygon(0 0, 80% 0, 100% 50%, 80% 100%, 0 100%);

                    sup{
                        display: none;
                        font-size: .5em;
                    }

                    &:before{
                        content: 'A';
                        display: block;
                        margin-left: -.25em;
                        vertical-align: middle;
                    }

                    &.a-triple{
                        sup{
                            display: inline;
                        }

                        &:before{
                            content: 'A';
                        }
                    }
                    &.a-double{
                        background-color: mix($white, $green, 20);
                        sup{
                            display: inline;

                            &:nth-child(2){
                                display: none;
                            }
                        }

                        &:before{
                            content: 'A';
                        }
                    }
                    &.a-plus{
                        background-color: mix($yellow, $green, 40);
                        sup{
                            display: inline;

                            &:nth-child(2),
                            &:nth-child(3){
                                display: none;
                            }
                        }

                        &:before{
                            content: 'A';
                        }
                    }
                    &.a{
                        background-color: mix($white, $yellow, 0);
                        &:before{
                            content: 'A';
                        }
                    }
                    &.b{
                        background-color: mix($yellow, $orange, 40);
                        &:before{
                            content: 'B';
                        }
                    }
                    &.c{
                        background-color: mix($orange, $red, 40);
                        &:before{
                            content: 'C';
                        }
                    }
                    &.d{
                        background-color: $red;
                        &:before{
                            content: 'D';
                        }
                    }
                }
            }
        }
    }

    #gallery{
        #product-carousel{
            margin-top: auto;
            margin-right: auto;
            margin-bottom: auto;
            height: 100vh;
            width: 100%;

            .item,
            .slick-list,
            .slick-track{
                height: 100%;
                overflow: visible;
                // clip-path: polygon(0 -100px, 100% -100px, 100% 100%, 0 100%);

                div,
                a{
                    display: block;
                    height: 100%;
                    outline: none;
                }
            }
        }

        video,
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    #versions{
        .versions-list{
            @include media-breakpoint-down(sm){
                flex-direction: column;
            }
            li{
                @include media-breakpoint-up(sm){
                    margin: 0 1.5rem;
                };

                &:first-child{
                    margin-left: 0;
                }

                &:last-child{
                    margin-right: 0;
                }

                a{
                    opacity: .2;
                    will-change: opacity;
                    transition: opacity .7s ease;

                    &:hover{
                        opacity: 1;
                    }
                }
            }
        }
    }
}
