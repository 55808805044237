html{
    will-change: filter;
    // transition: filter 1.2s ease;

    &.lightmode{
        // body,
        // header,
        // .app-page,
        // footer{
        //     color: $gray-900;
        //     background-color: $gray-100;
        // }

        filter: invert(1);

        #headerLogo{
            filter: invert(0);
        }
        
        .plant-legend{
            filter: invert(1);

            .planimetry{
                filter: invert(0);
            }
        }

        .app-overlay,
        img,
        .energy,
        .wpcf7-not-valid-tip,
        .wpcf7-response-output{
            filter: invert(1);
        }

        // .wpcf7-not-valid-tip{
        //     color: #E64A19;
        // }
        // .wpcf7-response-output{
        //     display: block;
        //     padding: .5rem 1rem;
        //     color: #E64A19;
        //     font-size: .8em;
        //     // border-left: 1rem solid #E64A19;
        //     background-color: rgba(#E64A19,.2);
        // }
        // &.invalid {
        //     .wpcf7-response-output{
        //         color: #fff;
        //         background-color: #E64A19;
        //     }
        // }
        //
        // &.sent {
        //     .wpcf7-response-output{
        //         color: #fff;
        //         background-color: $green;

    }
}

.app-overlay{
    &.light{
        color: $gray-900;
        background-color: mix($yellow, $gray-300, 2);
    }
}
