// TYPOGRAPHY

// 👉 Add the import of your project's webfonts (ex. Google Fonts, Iconfont, etc..)

// Iconfont
// Example: Ionicons 4.5
// @import url("https://unpkg.com/ionicons@4.5.5/dist/css/ionicons.min.css");

// Fontface
// Example: Noto Sans (Google Fonts: https://fonts.google.com/specimen/Noto+Sans)
// @import url('@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,400i,700,700i&display=swap');');


// ✅ If you want you could create some variables fort easy font changing
// Paste the node_modules/bootstrap/scss/_variables.scss in there and change the values you want

// 👇 Something like this one below
$system-font: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";

$brand-font: 'Basetica' !default;


* {
    /* Better Font Rendering */
    // text-rendering: optimizeLegibility; ⚠️ Optional - That Optimize Legibility (slower rendering)
    // text-rendering: optimizeSpeed; ⚠️ Optional - That Optimize Speed (faster rendering)
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    font-family: $system-font, sans-serif;
    font-weight: 300;
    // font-size: 16px; ⚠️ Optional - Usually the browsers set this value to 16px (that property is the base of a rem > 1rem = 16px)
    // Add CSS Attributes
}

h1,
.h1 {
    font-family: $brand-font, sans-serif;
    font-size: 3rem;
    // Add CSS Attributes

    @include media-breakpoint-down(sm){
        font-size: 2.4rem;
    }
}

h2,
.h2 {
    font-family: $brand-font, sans-serif;
    font-size: 2.4rem;
    // Add CSS Attributes
}


h3,
.h3 {
    font-family: $brand-font, sans-serif;
    font-size: 1.75rem;
    font-weight: 600;
    // Add CSS Attributes
}

h4,
.h4 {
    font-family: $brand-font, sans-serif;
    font-size: 1.5rem;
    // font-weight: 400;
    // Add CSS Attributes
}

h5,
.h5 {
    font-family: $brand-font, sans-serif;
    font-size: 1.2rem;
    // font-weight: 400;
    // Add CSS Attributes
}

h6,
.h6{
    font-family: $brand-font, sans-serif;
    font-size: 1rem;
    // font-weight: 400;
    // Add CSS Attributes
}

p {
    font-size: 1rem;
}

b,
strong{
    font-weight: bold;
}

a{
    color: inherit;

    &:hover{
        color: inherit;
        text-decoration: none;
    }
}

@import '../abstracts/_lettering.scss';
