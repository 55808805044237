section.focus{
    .content{
        .title{
            &::before{
                content: '';
                display: block;
                margin-bottom: 2rem;
                height: 1px;
                width: 3rem;
                background-color: $white;
            }
        }
        img{
            @include media-breakpoint-up(sm){
                max-width: 80%;
                min-width: 300px;
            }
        }
    }

    .gallery{
        [class*="slick-"]{
            height: 100%;
    
            & > div{
                height: 100%;
            }
        }
    
        img{
            padding: .5rem;
            @include object(cover, center);
            height: 500px;
        }
    }
}