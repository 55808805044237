// CASESTUDY LIGHTBOX
.lg-casestudy{

    header{
        &.lg-active{
            opacity: 0;
        }
    }

    .lg-outer{
        z-index: 15;

        .lg-item{
            .lg-img-wrap{
                height: 95vh;
                max-height: unset;
                pointer-events: all;

                img{
                    width: unset;
                    max-width: unset;
                    object-fit: contain;
                    border: none;
                }
            }
        }
    }
}

// LAMPS LIGHTBOX
.lg-lamps{

    header{
        &.lg-active{
            opacity: 0;
        }
    }

    .lg-outer{
        z-index: 15;

        .lg-item{
            @include media-breakpoint-up(sm){
                display: flex !important;
            }

            .lg-img-wrap{
                margin: 0;
                // height: 95vh;
                width: 100%;
                max-height: unset;
                pointer-events: all;
                
                @include media-breakpoint-up(sm){
                    padding-left: 3.5rem;
                    width: 50%;
                }

                img{
                    width: unset;
                    max-width: unset;
                    object-fit: contain;
                    border: none;

                    @include media-breakpoint-down(md){
                        height: 80px;
                    }
                }

            }
            .description{
                padding-left: 2rem;

                .info{
                    white-space: normal;

                    .title{
                        margin-top: 1rem;

                        @include media-breakpoint-up(sm){
                            margin-top: 0;
                        }

                        .color{
                            display: inline-block;
                            float: right;
                            margin-bottom: 1rem;
                            height: 5px;
                            width: 5px;
                            border-radius: 50%;
                            
                            @include media-breakpoint-up(sm){
                                display: block;
                                height: 10px;
                                width: 10px;
                            }
                        }
                    }

                    .content,                        
                    .technical-sheet{
                        display: none;
                        line-height: 1.25;

                        @include media-breakpoint-up(md){
                            display: block;
                        }
                    }

                    .light-scheme{
                        height: 120px;
                        width: auto;

                        @include media-breakpoint-up(sm){
                            height: auto;
                            width: 200px;
                        }
                    }
                }
            }
        }
    }
}
