section.before-after{
    // height: 300px;
    overflow: hidden;

    @include media-breakpoint-up(sm){
        height: 80vh;
    }

    img{
        @include object(cover,center);
    }
}