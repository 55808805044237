#cursor{
    position: absolute;
    display: block;
    height: 80px;
    width: 80px;
    text-align: center;
    white-space: nowrap;
    background-color: $white;
    // background-image: url('../img/vectors/icon-cursor.svg');
    // background-size: contain;
    // background-repeat: no-repeat;
    // background-position: center;
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(.2);
    mix-blend-mode: difference;
    pointer-events: none;
    transition: background-color .5s ease,transform 1s ease, opacity .25s ease;
    z-index: 20;

    &:before{
        content: attr(data-label);
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        color: rgba($black,0);
        font-family: $system-font;
        font-size: .6rem;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: .1em;
        transform: translate(-50%,-50%);
        z-index: 10;
        transition: color .4s ease;
    }

    &.hovering{
        // background-color: rgba($blue,.8);
        transform: translate(-50%, -50%) scale(1);

        &:before{
            color: $black;
        }
    }

    &.max-opacity{
        opacity: 1 !important;
    }

    &.min-opacity{
        opacity: .3;
    }

    &.max-index{
        z-index: 9;
    }

    &.mousedown{
        transform: translate(-50%, -50%) scale(.4);
    }
}
