#collage{
    .grid{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 30vh 30vh 30vh;

        @include media-breakpoint-down(sm){
            grid-auto-rows: 100px 100px 100px;
        }

        li{
            height: 100%;
            width: 100%;
            object-fit: cover;
            filter: saturate(0);
            transition: filter 1s ease;

            &:hover{
                filter: saturate(1);
            }

            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
                border-radius: .25em;
            }

            &:nth-child(1){
                grid-column: 1/2;
                grid-row: 1/2;
            }

            &:nth-child(2){
                grid-column: 2/3;
                grid-row: 1/2;
            }

            &:nth-child(3){
                grid-column: 3/4;
                grid-row: 1/2;
            }

            &:nth-child(4){
                grid-column: 1/2;
                grid-row: 2/4;
            }

            &:nth-child(5){
                grid-column: 2/4;
                grid-row: 2/4;
            }
        }
    }
}
