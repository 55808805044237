// MIXIN: Buttons

// Add every mixins you want.
// 👉 Example below: btn-default

@mixin btn-default{
    position: relative;
    display: flex;
    align-items: center;
    color: $white;
    font-family: $system-font;
    font-size: .8em;
    font-weight: bold;
    letter-spacing: .1em;
    text-transform: uppercase;
    background-color: transparent;
    border: none;

    &:hover,
    &.hovering{
        &:after{
            width: 20px;
        }
    }

    &:after{
        content: '';
        display: inline-block;
        margin-left: .25em;
        height: 2px;
        width: 50px;
        background-color: $white;
        transition: width .4s ease;
    }
}
