#page-title{
    display: block;
    max-height: 20px;
    min-width: 40px;
    transform-origin: 0 50%;
    // transform: translateY(20%);
    z-index: 1;
    pointer-events: all;
    will-change: opacity;
    transition: opacity 1.2s ease;

    &:before{
        content: '';
        display: block;
        position: absolute;
        // height: calc(100% + 3rem);
        height: calc(100% + 0%);
        width: 1px;
        background-color: $white;
    }

    .title{
        margin: 0 0 0 .5em;
        writing-mode: vertical-rl;
        text-orientation: sideways;
        white-space: nowrap;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        opacity: 1;
        transform: translateX(0);
        transition: transform .7s ease, opacity .7s ease, clip-path .7s ease;
    }

    &.active{
        .title{
            transform: translateX(-100%);
            clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
            opacity: 0;
        }

        .progress{
            opacity: 0;
        }
    }

    .progress{
        display: block;
        position: absolute;
        top: 2.25rem;
        height: calc(100% + 3rem);
        width: 2px;
        background-color: $white;
    }

}