&.wpcf7-form{
    br{
        display: none;
    }

    span.wpcf7-acceptance{
        .wpcf7-list-item{
            margin: 0 .25em 0 0;
        }
    }

    div.wpcf7-response-output {
        padding: u(1.5rem 2rem);
        border: 0;
        font-weight: 700;
    }

    .wpcf7-not-valid-tip{
        color: $red;
        font-size: .7em;
        font-weight: normal;
        letter-spacing: .05em;
        text-transform: uppercase;
    }
    .wpcf7-response-output{
        display: block;
        padding: .5rem 1rem;
        color: $red;
        font-size: .8em;
        // border-left: 1rem solid $red;
        background-color: $red;
    }
    &.invalid {
        .wpcf7-response-output{
            color: $white;
            background-color: $red;
        }
    }

    &.sent {
        .wpcf7-response-output{
            color: $white;
            background-color: $green;
        }
    }
}

.ajax-loader{
    display: none !important;
}
