body.about{
    min-height: 100vh;
  
    header{
        position: sticky;
    }
}

.page-about {
    
}
