.filters{
    a{
        font-size: .85rem;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: .05ch;
        opacity: .25;
        will-change: opacity;
        transition: opacity .4s ease;

        &:hover,
        &.active{
            opacity: 1;
        }
    }
}