#lightbox{
    display: none;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
}

.lg-backdrop{
    background-color: rgba($black, .85);
    // backdrop-filter: blur(.8rem);
    z-index: 9;
    transform: translateY(-100%);
    transition: transform 1.2s ease;

    &.in{
        transform: translateY(0);
    }
}

.lg-outer{

    .lg-inner{
        height: 85%;
        top: 50%;
        transform: translateY(-50%);
    }

    .lg-thumb{
        margin: 0 auto;

        .lg-thumb-item{
            border:none;
            border-radius: 0;

            transition: transform .4s ease, opacity .7s ease;

            &:not(.active){
                opacity: .1;
                transform: scale(.92);
            }

            &.active,
            &:hover{
                border-color: inherit;
            }
        }
    }

    .lg-toolbar{
        background-color: transparent;

        .lg-icon{
            color: $white;
            // font-family: 'Girolibero';
            font-size: 1rem;
        }

        .lg-autoplay-button{
            &:after{
                content:'\e941';
                display: inline-block;
                transform: rotate(-90deg);
            }
        }

        .lg-close{
            background-color: $gray-800;
            border: none;

            &:after{
                // content:'\e90e';
            }

            &.lg-icon{
                // color: $black;
            }
        }

        #lg-share{

            &:after{
                content: '\e915';
                font-size: 1.2rem;
            }

            .lg-dropdown{
                padding: 0;
                background-color: rgba($black, .2);

                li{
                    a{
                        color: $white;

                        &#lg-share-facebook{

                            .lg-icon{
                                padding: .5rem .5rem .1rem;
                                color: $black;
                                vertical-align: -.4rem;
                                border-radius: 1rem;
                                background-color: $black;

                                &:after{
                                    content: '\e900';
                                    vertical-align: .4rem;
                                }
                            }
                        }
                    }
                }

                &:after{
                    display: none;
                }
            }
        }

        #lg-counter{
            display: none;
        }
    }

    .lg-item{
        // position: relative;
        // display: flex;
        // align-items: center;
        height: unset;
        top: 50%;
        transform: translateY(-50%);

        @include media-breakpoint-down(sm){
            padding: 1rem;
            pointer-events: none;
        };

        &:before{
            content: '';
            display: none;
        }

        .lg-img-wrap{
            position: relative;
            margin: auto;
            height: 500px;
            max-height: 550px;
            // background-color: $white;

            @include media-breakpoint-down(sm){
                height: 260px;
            };

            img{
                height: 100% !important;
                width: 100% !important;
                object-fit: cover;
                object-position: center;
                max-width: 800px;
                border: .5rem solid $white;

                @include media-breakpoint-down(sm){
                    max-width: 100%;
                    border: none;
                };
            }
        }

        .description{
            max-width: 800px;

            @include media-breakpoint-down(sm){
                line-height: 1;
                white-space: normal;
            };

            .title{
                // opacity: .25;
                will-change: opacity;
                transition: opacity .7s ease;

                @include media-breakpoint-down(sm){
                    font-size: 1.5rem;
                };

                &.hovering{
                    opacity: 1;
                }
            }

            .info{
                p{
                    line-height: 1;
                }
            }

            a{
                pointer-events: all;
                @include media-breakpoint-down(sm){
                    font-size: 1rem;
                    z-index: 10;
                };
            }
        }
    }

    .lg-actions{
        .lg-icon{
            font-size: 1rem;
        }

        .lg-next{
            padding: .75rem .5rem;
            right: 0;
            height: 3rem;
            width: 3rem;
            color: $black;
            font-size: 1rem;
            text-align: center;
            border-radius: 0;
            background-color: $white;
            transform: translateX(0);

            transition: transform .4s ease;

            &:hover{
                color: $black;
                background-color: darken($white, 5);
            }

            &:before{
            }

            &.disabled{
                opacity: 1;
                transform: translateX(110%);
            }

            @include media-breakpoint-down(sm){
                top: unset;
                opacity:1;
                bottom: 0;
            }
        }

        .lg-prev{
            @extend .lg-next;
            left: 0;

            &:before{
            }
            &:after{
            }

            &.disabled{
                opacity: 1;
                transform: translateX(-110%);
            }
        }
    }

    .lg-progress-bar{
        top: inherit;
        bottom: 0;

        .lg-progress{
            background-color: $black;
        }
    }

    .lg-sub-html{
        // display: none;
        margin-bottom: 1rem;
        font-size: 1.2rem;
        background-color: rgba($black, 0);

        &.expanded{
            background-color: rgba($black, .2);
        }
    }

    .lg-thumb-outer{
        .lg-icon{
            color: $white;
            font-size: 1rem;
        }

        .lg-toogle-thumb{
            top: -40px;
            right: 0;
            background-color: transparent;
            // &:after{
            //     content: '\e908';
            // }
        }
    }
}
// Temporary
.lg-download{
  display: none;
}



// HEADER if .lg-active (Lightbox is Active)
header.lg-active{
    #menu-toggler,
    #claim,
    #darkmode,
    #select-language,
    #page-title{
        opacity: 0;
        pointer-events: none;
    }

    #company{
        opacity: .15 !important;

        @include media-breakpoint-down(sm){
            opacity: 0 !important;
        }
    }
}