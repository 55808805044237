section.showcase{
    @include media-breakpoint-up(sm){
        height: 300px;
    }
    
    [class*="slick-"]{
        height: 300px;

        & > div{
            height: 300px;
        }
    }

    img{
        padding: .5rem;
        @include object(cover, center)
    }

    .caption{
        opacity: .5;
    }
}